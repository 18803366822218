.App {
  text-align: center;
  background-color: black;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.switch-button {
  color: rgb(113, 118, 123);
  font-weight: 400;
}

.switch {
  position: fixed;
  top: 0px;
  width: 100% !important;
  flex-grow: 1;
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  z-index: 1;
}

.switch-content {
  margin: 0 auto;
  max-width: 650px;
  border-bottom: 1px solid rgba(237, 244, 255, 0.171);
}

.switch-button {
  width: 50%;
  height: 56px;
  padding: 0px 16px;
}

.switch-button > div 
{
  line-height: 56px;
  width: fit-content;
  margin: 0 auto;
  height: 100%;
  vertical-align: middle;
}

.active-switch-btn > div {
  border-bottom: 4px solid #1d9bf0;
  color: #fff;
  font-weight: 700;
}


.switch-button:hover {
  background-color: rgba(237, 244, 255, 0.068);
  cursor: pointer;
}

.border-l {
  border-left: 1px solid rgba(237, 244, 255, 0.171);
}

.border-r {
  border-right: 1px solid rgba(237, 244, 255, 0.171);
}

.switch-button.active {
  color: rgb(231, 233, 234);;
  font-weight: 700;
}

.tweet-content {
  width: 100%;
}

body {
  background-color: #000;
  min-height: 100vh;
}

.tweet-body {
  color: rgb(231, 233, 234);
  width: 100% !important;
  flex-grow: 1;
  max-width: 650px;
  margin: 0 auto;
  padding: 16px;
  white-space: pre-wrap;
  border: 1px solid rgba(237, 244, 255, 0.171);
  .tweet-avatar {
    width: 40px !important;
    img {
      width: 40px !important;
      height: 40px !important;
      max-width: 40px;
      border-radius: 50%;
    }
  }
  .username {
    font-weight: 700;
    font-size: 17px;
    line-height: 8px;
    img {
      margin-top: -2px;
      width: 16px;
      height: 16px;
    }
  }
}

.verified {
  margin-top: -2px;
  width: 16px;
  height: 16px;
}

.font-secondary {
  color: rgb(113, 118, 123);
  font-weight: 400;
}

.btn-main {
  margin-left: auto;
  border: 1px solid rgba(237, 244, 255, 0.171);
  max-width: 140px;
}

.btn-main:hover {
  background-color: rgba(237, 244, 255, 0.171);
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@media only screen and (max-width: 1280px) {
  .header {
    display: none;
  }

  .tweet-meta {
    font-size: 14px;
  }
  .side-menu {
    width: 100% !important;
  }
}

@media only screen and (max-width: 500px) {
  .switch-button {
    font-size: 14px;
  }

  .about-content {
    font-size: 13px;
    padding: 0px 17px;
  }

  .main-title {
    font-size: 18px;
  }

  .tweet-count {
    font-size: 14px;
  }
  
  .username>a {
    font-size: 15px;
  }

  .tweet-text {
    font-size: 14px;
    padding-right: 15px !important;
  }

  .userhandle-lg, .owner-lg {
    display: none;
  }

  .borger {
    height: 60px; 
  }
}

@media only screen and (min-width: 500px) { 
  .userhandle-sm, .owner-sm {
    display: none;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header {
  background-color: transparent;
  z-index: 100;
}

.side-menu {
  background-color: #121213ec;
  z-index: 900;
  min-width: 350px;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
}



.about-overlay {
  background-color: #121213ec;
  color: white;
  z-index: 9999;
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  position:fixed;
  top:0;
}

.loading-overlay {
  background-color: #121213ec;
  color: white;
  z-index: 9999;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  position:fixed;
  top:0;
}

.load-error-message {
  width: 100vw;
  margin: 0 auto;
  position: absolute;
  top: 48%;
}

.header-right {
  a:hover {
    text-decoration: underline;
  }
}

.about-title {
  padding: 20px 0px;
  font-size: 19px;
  font-weight: 500;
}

.about-content {
  max-width: 700px;
  color: #bebebe;
}

.about-paragraph {
  padding-bottom: 8px;
}

.about-btn {
  margin: 0 auto;
}

.btn-tweet {
  margin-left: 0 !important
}

.link-underline:hover {
  text-decoration: underline;
}

.loader {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: block;
  margin:15px auto;
  position: relative;
  color: #FFF;
  box-sizing: border-box;
  animation: animloader 2s linear infinite;
}

@keyframes animloader {
  0% {
    box-shadow: 14px 0 0 -2px,  38px 0 0 -2px,  -14px 0 0 -2px,  -38px 0 0 -2px;
  }
  25% {
    box-shadow: 14px 0 0 -2px,  38px 0 0 -2px,  -14px 0 0 -2px,  -38px 0 0 2px;
  }
  50% {
    box-shadow: 14px 0 0 -2px,  38px 0 0 -2px,  -14px 0 0 2px,  -38px 0 0 -2px;
  }
  75% {
    box-shadow: 14px 0 0 2px,  38px 0 0 -2px,  -14px 0 0 -2px,  -38px 0 0 -2px;
  }
  100% {
    box-shadow: 14px 0 0 -2px,  38px 0 0 2px,  -14px 0 0 -2px,  -38px 0 0 -2px;
  }
}

.btn-disabled {
  opacity: 60%;
  pointer-events: none;
}

.borger {
  width: 60px;
  padding: 15px;
  cursor: pointer;
}

.borger:hover {
  border-radius: 50%;
  background-color: #6e6e6e34;
}


.feed-spinner {
  width: 48px;
  height: 48px;
  position: absolute;
  opacity: 80%;
  top: 48%;
  border: 5px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

.btn-side-menu {
  margin: 0 auto !important;
}